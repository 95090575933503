import React from "react";
import ReactDOM from "react-dom";
import Toast from "./components/utility/Toast";

import { logoutRedirect } from "./electron/electronUtils";
// const electron = require("electron");
// const { ipcRenderer } = require("electron");
// const { ipcRenderer } = window.require("electron");
// import { ipcRenderer } from "electron";
// import { IpcRenderer } from "electron";
// const { ipcRenderer, ipcMain } = window.require("electron");
// const ipc = require("electron").ipcRenderer;
// const ipc = window.require("electron").ipcRenderer;
// const { electron } = window;

// const handleLogout = () => {
//   // Handle logout action
//   console.log("Logging out...");

//   ipc.send("logoutRedirect");
//   // Send IPC message to main process for redirection
//   // ipcRenderer.send("redirect-to-home");
//   // logoutRedirect();
//   // electron.logoutRedirect();
// };

// const handleLogout = () => {
// Handle logout action
// console.log("Logging out...");
// window.Electron.handleLogout();

// Send IPC message to main process for redirection
// if (window.location.protocol === "file:") {
// Electron environment
// const { ipcRenderer } = require("electron");
// const { ipcRenderer } = window.require("electron");
// ipcRenderer.send("redirect-to-home"); // Custom event to handle logout in main process
// } else {
//   // Web environment
//   // Perform any additional web-specific logout logic
//   console.log("Redirecting to home page...");
//   // Replace the following line with your actual web redirection logic
//   logoutRedirect(); // Example: Redirect to home page using a function from electronUtils
// }
// };

// Show the loading spinner initially
ReactDOM.render(
  <div id="loading-spinner">Loading...</div>,
  document.getElementById("secondary-root")
);

// show the Toast ... this fn is equivalent to App in ./index.tsx
ReactDOM.render(
  <React.StrictMode>
    <Toast
      open={true}
      message="Are you sure you want to logout?"
      handleClose={() => window.close()}
      boxStyle={{
        width: "100%",
        height: "100%",
        top: "0px",
        left: "0px",
        right: "0px",
        display: "flex",
      }}
      // handleAction={handleLogout}
      // handleAction={() => window.Electron.handleLogout()}
      handleAction={() => window.electron.handleLogout()}
    />
  </React.StrictMode>,
  document.getElementById("secondary-root")
);
